import React, { Fragment } from 'react'
import Layout from '../../layout'
import Accordion from '../../components/BuildingAppsCrashCourse/Accordion'
import ConsoleBanner from '../../components/BuildingAppsCrashCourse/ConsoleBanner'
import LessonSection from '../../components/BuildingAppsCrashCourse/LessonSection'
import NextLesson from '../../components/BuildingAppsCrashCourse/NextLesson'
import LessonResources from '../../components/BuildingAppsCrashCourse/LessonResources'
import LessonHeader from '../../components/BuildingAppsCrashCourse/LessonHeader'
import YoutubeVideo from '../../components/BuildingAppsCrashCourse/YoutubeVideo'
import { transcript } from '../../components/BuildingAppsCrashCourse/transcript'
import { SEO } from '../../components'
import BuildingAppsCrashCourseOgImage from '../../assets/images/building-apps-crash-course-og-image.png'
import './building-apps-crash-course.scss'

const LivechatApis = () => (
  <Layout className="bacc_layout-styles" isCourseSite>
    <SEO
      title="LiveChat APIs in practice"
      description="Join our code-along programming session and learn how to add authorization and functional features to your app with the LiveChat APIs."
      image={BuildingAppsCrashCourseOgImage}
    />
    <div className="bacc_main-wrapper">
      <LessonHeader
        title="2. LiveChat APIs in practice"
        summary={
          <Fragment>
            <p>
              Welcome back to The Practical Guide to Building LiveChat Apps. In
              the second episode, you’ll implement authorization and functional
              methods to our application using the Accounts SDK and LiveChat
              Configuration API.
            </p>
            <p>Let’s get coding!</p>
          </Fragment>
        }
      />
      <YoutubeVideo id="HDto0LYLYSg" eventLabel="liveChat apis" />
      <section className="bacc_main-section">
        <h3 className="u-font-bold bacc_section-title">Video summary</h3>
        <div>
          {transcript.livechatApis.map((item, index) => (
            <Accordion
              key={index}
              num={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </section>
      <LessonResources
        transcriptSlug="building-apps-crash-course-livechat-apis.pdf"
        isRepo
        articleTitle="App authorization with sample apps"
        articleSlug="updates/learn-authorization-with-sample-apps/"
        pageName="LiveChat APIs in practice"
      />
      <NextLesson
        title="3. Deploy your app"
        description="You’ll learn how to deploy your app for production using an external service: Heroku, Netlify, and Firebase."
        slug="/building-apps-crash-course/deploy-your-app"
      />
      <LessonSection />
    </div>
    <ConsoleBanner />
  </Layout>
)

export default LivechatApis
